<template>
  <div style="background-color: #f8f8fd;min-height: 100vh;">
    <back-nav :icon="'img/backb.png'" :textColor="'#111'" :backColor="'none'" />
    <div class="gt_top display_flex justify-content_flex-center align-items_center">
      <div class="gt_nav_ margin-lr-sm" v-for="(item, index) in navList" :key="index" @click="rankchange(item.type)">
        <span :class="{ 'gt_curt_': navCur == index }">{{ item.name }}</span>
      </div>
    </div>
    <div class="gt_type display_flex justify-content_flex-justify align-items_center">
      <div :class="typeCur == index ? 'gt_cur_' : 'gt_default_'" v-for="(item, index) in typeList" :key="index"
        @click="typechange(index)">
        <span>{{ item.name }}</span>
        <img v-if="typeCur == index" class="gt_line" src="img/gt_line.png" alt="">
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh" class="padding-bottom-xl"
      style="min-height: 300px;">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="fetchData">
        <div class="gt_item display_flex justify-content_flex-justify align-items_center"
          v-for="(item, index) in indexData" :key="index">
          <div class="display_flex align-items_center">
            <img class="gt_head" :src="item.head_portrait" alt="">
            <div class="suc_title">
              <p style="color: #111;">{{ item.nickname }}</p>
              <div class="display_flex align-items_center margin-tb-sm">
                <img v-if="item.gift_type == 6" src="img/YouMe.png" alt="">
                <span style="color: #999999;">{{ item.created_at }}</span>
              </div>
              <div class="gt_money display_flex align-items_center justify-content_flex-center">
                <span class="gt_span">总价值</span>
                <div v-if="item.total_charm_value > 0" class="display_flex align-items_center justify-content_flex-center">
                  <img src="img/bi.png" alt="">
                  <span class="gt_count">{{ item.total_charm_value }}</span>
                </div>
                <div v-if="item.total_gold > 0" class="display_flex align-items_center justify-content_flex-center">
                  <img src="img/yin_bi.png" alt="">
                  <span class="gt_count">{{ item.total_gold }}</span>
                </div>


              </div>
            </div>
          </div>
          <div class=" display_flex flex-direction_column align-items_flex-end">
            <div class="gt_giftName display_flex align-items_center">
              <p style="color: #111;">{{ item.gift_name }}</p>
              <!-- <img v-if="item.gift_type == 8 || item.gift_type == 1 || item.gift_type == 2" class="gt_mood" :src="`img/mood${item.gift_type}.png`" alt=""> -->
            </div>
            <div class="gt_imgs display_flex align-items_center">
              <van-image class="gt_gift" fit="contain" :src="item.gift_url" />
              <span style="color: #111;">x{{ item.num }}</span>
            </div>
          </div>
        </div>

      </van-list>
      <div class="noData margin-top-xl" v-if="noData">
        <img src="img/noData.png" alt="">
      </div>
    </van-pull-refresh>


  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import { giftRecordDetail } from "@/api/api";
import BackNav from "@/components/backNav.vue";
export default {
  name: 'giftCash',
  components: {
    BackNav,
  },
  data() {
    return {
      navList: [
        {
          name: '送出的礼物',
          type: 0
        },
        {
          name: '收到的礼物',
          type: 1
        }
      ],
      typeList: [
        {
          name: '房间',
          type: 1
        },
        // {
        //   name: '声动总动员',
        //   type: 2
        // },
        {
          name: '聊天',
          type: 3
        },
        // {
        //   name: '幸运',
        //   type: 3
        // },
        {
          name: '奇遇探险',
          type: 9
        },
        {
          name: '玩法',
          type: 12
        }
      ],
      navCur: 0,
      typeCur: 0,
      noData: false,
      page: 1,
      row: 10,
      loading: false,
      finished: false,
      refreshing: false,
      indexData: []

    };
  },
  computed: {

  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    // this.fetchData()
  },
  // mounted() {

  //   window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动
  // },
  // destroyed() {
  //   window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  // },
  methods: {
    // Scrollbottom() {
    //   let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    //   let clientHeight = document.documentElement.clientHeight;
    //   let scrollHeight = document.documentElement.scrollHeight;
    //   if (scrollTop + clientHeight >= scrollHeight) {

    //     this.fetchData()
    //     // this.pageNo++
    //     // console.log(this.pageNo);
    //     // this.fetchData()

    //   }
    // },
    rankchange(index) {
      this.navCur = index
      this.indexData = [];
      this.page = 1
      this.loading = true;
      this.finished = false;
      this.fetchData();
    },
    typechange(index) {
      this.typeCur = index
      this.indexData = [];
      this.page = 1
      this.loading = true;
      this.finished = false;
      this.fetchData();
    },
    fetchData() {
      if (this.refreshing) {
        this.indexData = [];
        this.page = 1
        this.refreshing = false;
      }
      let type = this.navCur + 1
      let gift_type
      console.log(this.typeCur)
      if (this.typeCur == 3) {
        gift_type = 9
      } else if (this.typeCur == 4) {
        gift_type = 12
      } else {
        gift_type = this.typeCur + 1
      }
      let param = {
        page: this.page,
        rows: this.row,
        type: type,
        gift_type: gift_type
      }
      giftRecordDetail(param).then(response => {
        this.indexData = this.indexData.concat(response.data);
        this.loading = false;
        if (response.data.length < 10) {
          this.finished = true;
        }
        if (this.page == 1 && response.data.length == 0) {
          this.noData = true
        } else {
          this.noData = false
        }
        this.page = this.page + 1
      },
        error => {
          this.loading = false;
          this.finished = true;
          this.$toast.fail(error)
        }).catch((error) => {
          this.loading = false;
          this.finished = true;
          this.$toast.fail(error)
        })
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.fetchData();
    },
    checkUser(id) {
      let param = id.toString()
      this.$bridge.callhandler('checkUser', param, (data) => {
        // 处理返回数据
      })
    },
    closePage() {
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
      })
    },

  },
};
</script>
<style>
/* html,
body {
  background-color: #171726;
  height: 100%;
  overflow: scroll;
} */
</style>